import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMachine } from '@xstate/react'
import interviewMachine from '../components/interviewMachine'
import LoadingPage from '../components/loadingPage'
import PreFormInterview from '../components/preInterviewComponents/preFormInterview'
import PreTextInterview from '../components/preInterviewComponents/preTextInterview'
import PreVideoInterview from '../components/preInterviewComponents/preVideoInterview'
import FormInterview from './formInterview'
import TextInterview from './textInterview'
import VideoInterview from './videoInterview'
import apiCalendar from '../services/api/calendar'
import apiInterviewVersions from '../services/api/interviewVersion'
import useWindowSize from '../components/useWindowSize'
import SmallerScreenNotification from '../components/smallerScreenNotification'
import NotificationBoxPage from '../components/notificationBoxPage'

const Switch = ({ render, children }) =>
  children.find((child) => child.props.value === render)
const Case = ({ children }) => children

export default function InterviewStageController() {
  const { t } = useTranslation()
  const { interviewToken } = useParams()
  const [width] = useWindowSize()
  const [state, send] = useMachine(interviewMachine, {
    services: {
      fetchInterview: () =>
        apiInterviewVersions.startInterview(interviewToken).then((res) => res),
      fetchInterviewAfterExpiration: () =>
        apiCalendar
          .getDataWithoutVerification(interviewToken)
          .then((data) => data),
    },
  })
  const navigate = useNavigate()
  const [showCounter, setShowCounter] = useState(true)

  const rescheduleInterview = () => {
    apiCalendar
      .resetInterview(interviewToken)
      .then((res) => {
        navigate(`/calendar/${res.updatedToken}`)
      })
      .catch(() => {
        send('LOAD_FAILURE')
      })
  }

  return (
    <Switch render={state.value}>
      <Case value="loading">
        <LoadingPage />
      </Case>
      <Case value="preFormInterview">
        {width <= 480 ? (
          <SmallerScreenNotification />
        ) : (
          <PreFormInterview
            onReady={() => send('READY')}
            interviewType={state.context.interviewType}
            interviewTime={state.context.interviewTime}
            setShowCounter={setShowCounter}
            showCounter={showCounter}
          />
        )}
      </Case>
      <Case value="preTextInterview">
        {width <= 480 ? (
          <SmallerScreenNotification
            rescheduleInterview={rescheduleInterview}
            rescheduleEnabled={state.context.rescheduleEnabled}
            isFinished={state?.context?.finished}
          />
        ) : (
          <PreTextInterview
            rescheduleInterview={rescheduleInterview}
            rescheduleEnabled={state.context.rescheduleEnabled}
            isFinished={state?.context?.finished}
            interviewType={state.context.interviewType}
            interviewTime={state.context.interviewTime}
            setShowCounter={setShowCounter}
            showCounter={showCounter}
          />
        )}
      </Case>
      <Case value="preVideoInterview">
        {width <= 480 ? (
          <SmallerScreenNotification
            rescheduleInterview={rescheduleInterview}
            rescheduleEnabled={state.context.rescheduleEnabled}
            isFinished={state?.context?.finished}
          />
        ) : (
          // <PreVideoInterview
          //   rescheduleInterview={rescheduleInterview}
          //   interviewType={state.context.interviewType}
          //   interviewTime={state.context.interviewTime}
          //   setShowCounter={setShowCounter}
          //   showCounter={showCounter}
          // />
          <PreVideoInterview
            rescheduleInterview={rescheduleInterview}
            rescheduleEnabled={state.context.rescheduleEnabled}
            isFinished={state?.context?.finished}
            interviewType={state.context.interviewType}
            interviewTime={state.context.interviewTime}
            setShowCounter={setShowCounter}
            showCounter={showCounter}
          />
        )}
      </Case>
      <Case value="formInterview">
        {width <= 480 ? <SmallerScreenNotification /> : <FormInterview />}
      </Case>
      <Case value="textInterview">
        {width <= 480 ? (
          <SmallerScreenNotification
            rescheduleInterview={rescheduleInterview}
          />
        ) : (
          <TextInterview />
        )}
      </Case>
      <Case value="videoInterview">
        {width <= 480 ? (
          <SmallerScreenNotification
            rescheduleInterview={rescheduleInterview}
          />
        ) : (
          <VideoInterview />
        )}
      </Case>
      <Case value="success">
        <NotificationBoxPage
          messageType="success"
          message={t('pages.thankYouMsg')}
        />
      </Case>
      <Case value="end">
        <NotificationBoxPage
          messageType="error"
          message={t('pages.interviewEnded')}
        />
      </Case>
      <Case value="error">
        <NotificationBoxPage
          messageType="error"
          message={t('pages.linkExpired')}
          rescheduleInterview={rescheduleInterview}
          rescheduleEnabled={state.context?.rescheduleEnabled}
          isFinished={state?.context?.finished}
          isExpired={state.context?.errorMsg === 'expired token'}
        />
      </Case>
    </Switch>
  )
}
