import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import styled from '@emotion/styled'
import Carousel from 'react-bootstrap/Carousel'
import { Container } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import theme from '../constants/theme'

export const Header = styled('div')(() => ({
  height: '6%',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 10px 20px #12263F08',
  justifyContent: 'space-between',
}))

export const LogoContainer = styled(Col)(() => ({
  width: '15.75rem',
  borderRight: '1px solid #F8F8FB',
  height: '100%',
}))

export const CustomOrangeButton = styled(Button)(() => ({
  background: theme.brandColorOrange,
  border: `0.063rem solid ${theme.brandColorOrange}`,
  color: theme.white,
  boxShadow: 'none !important',
  margin: '0.45rem 0',
  padding: '5px 40px',
  '&:hover': {
    background: theme.whiteButtonBackground,
    border: `0.063rem solid ${theme.brandColorOrange}`,
    color: `${theme.brandColorOrange} !important`,
  },
  '&:focus': {
    background: theme.defaultBackground,
    border: `0.063rem solid ${theme.brandColorOrange}`,
    color: `${theme.brandColorOrange} !important`,
  },
}))

export const QuestionContainer = styled('div')(() => ({
  textAlign: 'center',
  paddingBottom: '78px',
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
}))

export const QuestionTextContainer = styled('div')(() => ({
  textAlign: 'left',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: `1px solid ${theme.headersMiniTitle}`,
  borderRadius: '5px',
  padding: '9px 12px',
  fontWeight: 'bold',
  color: theme.headersMiniTitle,
  margin: '5px 0 5px 0',
  height: 'auto',
}))

// form question
export const FormContainer = styled(Container)(() => ({
  maxWidth: '80%',
  height: `calc(100vh - 113px)`,
  overflow: 'auto',
  paddingBottom: '20px'
}))

export const QuestionFormContainer = styled('div')(() => ({
  textAlign: 'left',
  padding: '9px 12px',
  fontWeight: 'bold',
  margin: '10px 0 5px 0',
}))

export const QuestionAnswerFormContainer = styled('div')(() => ({
  color: theme.headersMiniTitle,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  margin: '15px 0',
  padding: '7px 20px',
}))

// interview demo
export const WarningImg = styled('img')(() => ({
  display: 'block',
  height: 'auto',
  maxHeight: '450px',
}))

export const ButtonsContainer = styled('div')(() => ({
  textAlign: 'center',
  marginTop: '-100px',
  bottom: 0,
  left: '50%',
  right: '50%',
  zIndex: 15,
}))

export const SpeedTestContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '5px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  transition: '0.3s',
  margin: '10px',
}))

export const LoadingMessage = styled('div')(() => ({
  fontSize: '16px',
  color: theme.brandColorOrange,
  margin: '10px',
}))

export const UploadSpeed = styled('div')(() => ({
  fontSize: '15px',
  fontWeight: 'bold',
  margin: '5px',
  display: 'flex',
  alignItems: 'center',
}))

export const SpeedIndicator = styled('div')(() => ({
  fontSize: '14px',
  fontStyle: 'italic',
  color: '#444',
  margin: '5px',
  display: 'flex',
  alignItems: 'center',
}))

export const CameraButton = styled(Button)(() => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
}))

export const OutlineCameraButton = styled(CameraButton)(() => ({
  backgroundColor: 'transparent',
  color: theme.white,
}))

export const TestSubmitButton = styled(Button)(() => ({
  marginLeft: '5px',
  marginRight: '5px',
  backgroundColor: theme.brandColorOrange,
  color: theme.white,
  '&:hover': {
    backgroundColor: theme.headersMiniTitle,
    color: theme.white,
  },
}))

export const CarouselSVGWrapper = styled('div')(() => ({
  margin: '0 auto',
  width: '90%',
  maxWidth: '850px',
}))

// text interview
export const QuestionTableContainer = styled(Row)(() => ({
  padding: '80px 0 0 0',
  position: 'relative',
  alignItems: 'center',
  margin: '0',
}))

export const QuestionMiddleTableCell = styled('div')(() => ({
  display: 'table-cell',
  verticalAlign: 'middle',
  alignItems: 'center',
  width: '100%',
  textAlign: 'center',
}))

export const CustomQuestionContainer = styled(QuestionContainer)(() => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'center',
  paddingBottom: '0',
}))

export const CustomOutlineButton = styled(Button)(() => ({
  background: theme.white,
  border: `0.063rem solid ${theme.brandColorOrange}`,
  color: theme.brandColorOrange,
  boxShadow: 'none !important',
  margin: '0.45rem 0',
  padding: '5px 40px',
  '&:hover': {
    background: theme.brandColorOrange,
    border: `0.063rem solid ${theme.brandColorOrange}`,
    color: `${theme.white} !important`,
  },
  '&:focus': {
    background: theme.brandColorOrange,
    border: `0.063rem solid ${theme.brandColorOrange}`,
    color: `${theme.white} !important`,
  },
  '&:disabled': {
    background: theme.white,
    borderColor: '#CED4DA',
    color: '#CED4DA',
  },
}))

export const ContainerWithWaves = styled('div')(() => ({
  background: theme.brandColorOrange,
  padding: '20px',
  width: 'fit-content',
  margin: '10px auto 0 auto',
  borderRadius: '100%',
  position: 'relative',
  display: 'inline-block',
}));

export const Wave = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  margin: 'auto',
  borderRadius: '50%',
  background: '#3393C1',
  zIndex: -10,
  animation: 'wave 2s infinite',
}));


export const IconButton = styled(Button)(() => ({
  background: 'transparent !important',
  color: `${theme.headersMiniTitle} !important`,
  border: `none !important`,
  borderRadius: '7px',
  boxShadow: 'none !important',
  margin: '0.45rem 0',
  fontSize: '1.5rem',
}))

export const TextSpeechButton = styled(Button)(() => ({
  background: 'transparent !important',
  color: theme.headersMiniTitle,
  border: `none !important`,
  boxShadow: 'none !important',
  margin: '0.45rem 0',
  fontSize: '1.5rem',
  '&:hover': {
    color: `${theme.white} !important`,
  },
}))

export const IconButtonWrapper = styled(TextSpeechButton)(() => ({
  position: 'absolute',
  color: theme.white,
  left: 0,
  top: '-5px',
  margin: 'auto',
}));

export const Footer = styled('div')(() => ({
  position: 'fixed',
  left: '0',
  padding: `calc(${theme.gridGutterWidth} / 2)`,
  right: '0',
  bottom: '0',
  color: theme.brandColorOrange,
  height: '6vh',
  width: '100%',
  backgroundColor: theme.footerBg,
  textAlign: 'center',
  fontSize: '0.8125rem',
  '@media print': {
    display: 'none !important',
  },
}))

export const FooterContent = styled('span')({
  color: theme.footerSpanColor,
})


export const Typography = styled('span')(
  ({ color, size, bold, variant, regular }) => ({
    color,
    fontSize: size,
    // eslint-disable-next-line no-nested-ternary
    fontFamily: regular
      ? 'PoppinsRegular'
      : bold
      ? 'PoppinsSemiBold'
      : 'PoppinsMedium',
    textDecoration: 'none',
    textTransform: variant || 'none',
  }),
)

export const GradientBackground = styled('div')(({ background }) => ({
  background,
}))

export const PreInterviewWrapper = styled('div')(({ background }) => ({
  background,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'auto',
  position: 'relative'
}))

export const PreInterviewInnerWrapper = styled('div')(() => ({
  height: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'auto',
  padding: '20px 0',
  position: 'relative'
}))

export const LogoWrapper = styled(Row) (() => ({
  position: 'sticky',
  top: '0',
  left: '0',
}))

export const GradientInterviewBackground = styled('div')(({ background }) => ({
  background,
  height: '100vh',
  overflow: 'hidden',
  position: 'relative',
}))

export const JobApplicationPositionCard = styled('div')(() => ({
  backgroundColor: theme.white,
  borderRadius: '5px',
  borderTop: `6px solid ${theme.brandColorOrange}`,
}))

export const NotificationMessagePageBox = styled('div')(({ color }) => ({
  backgroundColor: theme.white,
  border: `5px solid ${color}`,
  color,
  minWidth: '30%',
}))

export const ExternalLink = styled('a')(({ color, variant, decoration }) => ({
  color,
  textDecoration: decoration || 'none',
  textTransform: variant || 'none',
  '&:hover': {
    color,
    textDecoration: 'none',
    textTransform: variant || 'none',
  },
}))

export const Clock = styled('div')(() => ({
  padding: '24px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: '1 !important',
  borderTop: `3px solid ${theme.headersMiniTitle}`,
  '& h1': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '0',
  },
  '& h2': {
    color: theme.darkBlue,
  },
}))

export const CardHeader = styled('h2')(() => ({
  // backgroundColor: theme.headersMiniTitle,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  opacity: 0.8,
  color: theme.hintsNotifications,
  paddingTop: '5px',
  fontSize: '16px',
  fontWeight: '600',
  minHeight: '80px',
  // borderRadius: '12px 12px 0 0',
  textTransform: 'capitalize',
}))

export const FormTitleContainer = styled('div')(() => ({
  // width: '60%',
  position: 'sticky',
  top: 0,
  zIndex: 30,
}))

export const FormCardRibbon = styled('div')(() => ({
  backgroundColor: theme.brandColorOrange,
  color: theme.white,
  padding: '5px',
  fontSize: '25px',
  borderRadius: '10px 10px 0 0',
  textTransform: 'capitalize',
}))

export const FormCard = styled('div')(() => ({
  width: '100%',
  color: theme.headersMiniTitle,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  borderRadius: '10px 10px 0 0',
}))

export const FormCardHeaderSpecific = styled('span')(() => ({
  color: theme.brandColorOrange,
}))

export const FormCardHeader = styled('h1')(() => ({
  padding: '20px',
  fontSize: '30px',
  fontWeight: 'bold',
  textTransform: 'capitalize',
}))

export const CustomCard = styled('div')(() => ({
  borderColor: 'transparent !important',
  backgroundColor: theme.white,
  padding: '10px !important',
  boxShadow: '0px 10px 20px 0px rgba(18, 38, 63, 0.03) !important',
  height: 'calc(80vh - 300px - 16px)',
  overflowY: 'auto',
  overflowX: 'hidden',
}))

export const FlexCard = styled('div')(() => ({
  borderColor: 'transparent !important',
  backgroundColor: theme.white,
  padding: '10px !important',
  boxShadow: '0px 10px 20px 0px rgba(18, 38, 63, 0.03) !important',
  height: '300px',
}))

export const CalendarCard = styled(Card)(({ height }) => ({
  borderColor: 'transparent !important',
  backgroundColor: theme.white,
  padding: '10px !important',
  boxShadow: '0px 10px 20px 0px rgba(18, 38, 63, 0.03) !important',
  height: height || 'auto',
  borderRadius: '0px',
  overflowX: 'hidden',
  border: 'none',
}))

export const Title = styled('h1')(() => ({
  textAlign: 'center',
  fontSize: '22px',
  color: theme.headersMiniTitle,
  fontWeight: 700,
  textTransform: 'capitalize',
}))

export const RootContainer = styled('div')(() => ({
  height: '100vh',
  width: '100%',
}))

export const FixedRootContainer = styled('div')(() => ({
  // height: 'auto',
  width: '100vw',
}))

export const PageContainer = styled('div')(() => ({
  background:
    'transparent linear-gradient(0deg, #3927514D 0%, #80808000 100%) 0% 0% no-repeat padding-box',
  height: '90%',
  width: '100%',
  paddingBottom: '50px',
  overflow: 'auto',
}))

export const InnerPageContent = styled('div')(() => ({
  color: theme.headersMiniTitle,
  height: '100%',
  width: '100%',
}))

export const NoMarginRow = styled(Row)(() => ({
  width: '100%',
  height: '100%',
}))

export const MarginRow = styled(Row)(() => ({
  margin: '0 30px',
}))

export const LeftCol = styled(Col)(() => ({
  width: '22.3vw',
  marginTop: '20px',
  padding: '0 61px',
  fontWeight: 'bold',
}))

export const MiddleCol = styled(Col)(() => ({
  width: '55.2vw',
  margin: 'auto',
}))

export const VideoContainer = styled('div')(() => ({
  width: '44.5vw',
  height: '49.2vh',
  backgroundColor: '#BFBEBA',
  shadowBox: '0px 3px 6px #12263F33',
  borderRadius: '5px',
  textAlign: 'center',
  position: 'relative',
}))

export const RecordStatusText = styled('div')(() => ({
  textAlign: 'center',
  marginTop: '10px',
}))

export const CenterDiv = styled('div')(() => ({
  textAlign: 'center',
}))

export const RightDiv = styled('div')(() => ({
  textAlign: 'right',
}))

export const RightCol = styled(Col)(() => ({
  width: '19vw',
  // paddingLeft: '20px',
  marginTop: '20px',
  padding: '0 30px',
}))

export const CustomTransparentButton = styled(Button)(() => ({
  background: 'transparent !important',
  color: `${theme.headersMiniTitle} !important`,
  border: `0.063rem solid ${theme.brandColorOrange} !important`,
  borderRadius: '7px',
  boxShadow: 'none !important',
  margin: '0.45rem 0',
}))

export const PreInterviewCarousel = styled(Carousel)`
  min-height: '80vh';
  > .carousel-inner .carousel-item {
    width: 100%;
    height: 100%;
    border-radius: 0;
    img {
      object-fit: cover;
    }
  }
  > .carousel-control-prev,
  > .carousel-control-next {
    background-color: #3c3a55;
    color: #FFFFFF;
    font-size: 20px;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: 50%;
    &:hover {
      background-color: #343a40;
      color: #FFFFFF;
    }
  }
`

export const PreInterviewCarouselCaption = styled(Carousel.Caption)(() => ({
  color: 'black',
  position: 'relative',
  left: 'auto',
  right: 'auto',
  bottom: 'auto',
}))

// new design styles

export const OverlayDiv = styled('div')(() => ({
  position: 'fixed',
  zIndex: 999,
  backgroundColor: theme.talentinoBlack,
  boxShadow: '0px 15px 20px 0px rgba(18, 38, 63, 0.15)',
  opacity: '0.3',
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
}))

export const PreInterviewInstructionImg = styled('img')(() => ({
  display: 'block',
  height: 'auto',
  maxHeight: '450px',
}))

export const CalendarPickerContainer = styled('div')(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: '9999',
  backgroundColor: theme.white,
  padding: '20px 15px',
  minHeight: '610px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))

export const MainContainer = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  (props) => ({
    backgroundColor: props.backgroundColor || theme.mainContentBg,
    justifyContent: props.centered ? 'center' : undefined,
    alignItems: props.centered ? 'center' : undefined,
    paddingBottom: props.centered ? '50px' : undefined,
  }),
)

export const CompanyDetailsDetailsRow = styled('tr')({
  borderTop: '15px solid transparent'
})

export const CompanyDetailsDetailsCell = styled('td')({
  display: 'flex',
  alignItems: 'start',
  fontSize: '16px',
  fontWeight: '600',
  color: theme.hintsNotifications,
  width: '218.109px',
})

export const CompanyDetailsDetailsContent = styled('td')({
  fontSize: '14px',
  fontWeight: '500',
  color: theme.talentinoDarkerGray,
})

export const JobDetailsDetailsWrapper = styled('div')({
  padding: '3px',
})

export const JobDetailsDetailsSection = styled('div')(
  ({ borderTop }) => ({
  borderTop: `${borderTop} 24px solid transparent`
}),
)

export const JobDetailsDetailsHeader = styled('h3')({
  display: 'flex',
  alignItems: 'start',
  fontSize: '16px',
  fontWeight: 600,
  color: theme.hintsNotifications,
  width: '218.109px',
})

export const JobDetailsDetailsContent = styled('p')({
  fontSize: '14px',
  fontWeight: 500,
  color: theme.talentinoDarkerGray,
})

export const CalendarTimeBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '16px 0',
  backgroundColor: theme.talentinoOfWhite,
  width: 'fit-content',
  padding: '16px',
  gap: '16px',
  borderRadius: '8px',
})

export const ImageContainer = styled('div')({
  width: '100%',
})

export const StyledContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: '1',
  height: '100%',
  marginTop: '50px',
})

export const StyledRow = styled(Row)({
  display: 'flex',
  width: '100%',
  gap: '10px',
  backgroundColor: theme.mainContentBg,
})

export const StyledCol = styled(Col)({
  height: '100%',
  padding: '0',
  flexGrow: 1,
  position: 'relative',
})

export const StyledColFlex = styled(Col)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px',
  justifySelf: 'stretch',
  height: '100%',
})

export const CalendarLogoContainer = styled('div')({
  position: 'absolute',
  top: 15,
  zIndex: 100,
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
})

export const LogoImage = styled('img')({
  backgroundColor: theme.talentinoOfWhite,
  borderRadius: '50%',
  padding: '5px',
})

export const StyledRowAuto = styled(Row)({
  height: '60%',
  overflowY: 'auto',
})

export const InstructionsContainer = styled('div')({
  background: theme.white,
  width: 'calc(100% - 40px)',
  display: 'flex',
  height: '62px',
  padding: '16px 24px',
  margin: '0 20px',
})

export const InstructionsText = styled('p')({
  color: theme.hintsNotifications,
  fontSize: '18px',
  fontWeight: '600',
})

export const StepsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  marginTop: '10px',
  marginLeft: '40px',
})

export const StepsText = styled('p')({
  color: theme.talentinoDarkerGray,
  fontSize: '14px',
  fontWeight: '400',
  width: '400px',
})

export const CarouselButton = styled('button')(
  {
    width: '14px',
    height: '5px',
    borderRadius: '8px',
    border: 'none',
  },
  (props) => ({
    backgroundColor: props.selected ? theme.talentinoBlue : theme.talentinoSkyBlue,
  }),
)

export const BackButton = styled('button')(
  {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 16px',
    height: '36px',
    width: '150px',
    borderRadius: '2px',
    background: 'transparent',
    border: 'none',
    color: theme.hintsNotifications,
    fontSize: '12px',
    fontWeight: '500',
  },
  (props) => ({
    display: props.carouselSelected === 1 ? 'none' : 'flex',
  }),
)

export const ContentContainer = styled('div')(
  ({ width, marginTop, justifyContent, padding }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: justifyContent || 'center',
    alignItems: 'center',
    width: width || '100%',
    height: '100%',
    padding: padding || '30px',
    flexGrow: 1,
    marginTop: marginTop || 'auto',
  }),
)

export const CarouselContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
})

export const CarouselTitle = styled('h2')({
  color: theme.hintsNotifications,
  fontSize: '18px',
  fontWeight: '600',
  marginTop: '10px',
})

export const CarouselText = styled('p')({
  color: theme.talentinoDarkerGray,
  fontSize: '14px',
  fontWeight: '400',
  marginTop: '10px',
})

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2px',
  marginTop: '10px',
})

export const FooterContainer = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  width: '100%',
  gap: '10px',
  marginTop: 'auto',
})

export const StyledHeading = styled('h2')({
  color: theme.talentinoSpecialBlue,
  fontSize: '18px',
  fontWeight: '600',
  marginTop: '24px',
  textAlign: 'center',
})

export const StyledParagraph = styled('p')(
  ({ color }) => ({
  color: color || theme.hintsNotifications,
  fontSize: '16px',
  fontWeight: '400',
  marginTop: '10px',
  textAlign: 'center',
  })
)

export const InterviewVideoPreviewContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#000000',
  borderRadius: '8px',
  marginBottom: '10px',
  width: '100%',
})

export const InterviewButtonsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
  gap: '25px',
  backgroundColor: theme.white,
  borderRadius: '0 0 7px 7px',
})

export const InterviewQuestionsContainerBox = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  marginTop: '25px',
  gap: '10px',
})

export const InterviewQuestionTag = styled('p')({
  width: '100%',
  color: theme.hintsNotifications,
  fontSize: '16px',
  fontWeight: '400',
  textTransform: 'capitalize',
  textAlign: 'start',
})

export const InterviewQuestionsTextContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  gap: '10px',
})

export const InterviewQuestionText = styled('p')({
  fontSize: '18px',
  fontWeight: '600',
})

export const InterviewTimerBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '6px',
  backgroundColor: theme.talentinoOfWhite,
  padding: '16px',
})

export const InterviewTimerText = styled('h2')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.talentinoBlue,
  fontSize: '16px',
  fontWeight: '600',
  textTransform: 'capitalize',
  gap: '6px',
  marginBottom: '0px',
})

export const QuestionsProgressCard = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '12px',
  marginBottom: '16px',
  backgroundColor: theme.white,
})

export const QuestionsProgressContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: '6px',
  padding: '16px',
})

export const Separator = styled('div')(({ margin }) => ({
  border: '1px solid #CED4DA',
  margin: margin || '10px 0',
  width: '100%',
}))

export const QuestionsProgressTitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: '11px',
})

export const QuestionsProgressTitle = styled('h2')({
  color: theme.hintsNotifications,
  fontSize: '14px',
  fontWeight: '600',
})

export const PreInterviewCountDownTitle = styled('h2')({
  color: theme.hintsNotifications,
  fontSize: '22px',
  fontWeight: '600',
  marginBottom: '24px',
})

export const QuestionsProgressText = styled('h2')({
  color: theme.hintsNotifications,
  fontSize: '12px',
  fontWeight: '500',
  textTransform: 'capitalize',
})

export const CountdownCard = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '150px',
  height: '150px',
  padding: '16px',
  gap: '12px',
  borderRadius: '4px',
  borderTop: '2px solid #BBCCD8',
  background: theme.talentinoOfWhite,
})

export const CountdownCardHeader = styled('p')({
  color: theme.talentinoDarkerGray,
  fontSize: '16px',
  fontWeight: '500',
})

export const CountdownCardBody = styled('p')({
  color: theme.talentinoSpecialBlue,
  fontSize: '22px',
  fontWeight: '600',
})

export const CountDownBoxWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.white,
  padding: '40px',
  borderRadius: '8px',
  boxShadow: '0px 15px 20px 0px rgba(18, 38, 63, 0.15)',
})


export const NotificationBox = styled('div')(
  ({ color, minWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.white,
  padding: '40px',
  borderRadius: '8px',
  boxShadow: '0px 15px 20px 0px rgba(18, 38, 63, 0.15)',
  minWidth: minWidth || '465px',
  '& p': {
    color,
    fontSize: '20px',
    fontWeight: '600',
    marginTop: '24px'
  }
})
)

export const CareerTitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '0.1rem',
  alignItems: 'center',
  textAlign: 'start',
  width: '100%',
}));

export const CareerTitle = styled('h2')(() => ({
  fontSize: '1.1rem',
  fontWeight: '500',
  color: '#333',
  letterSpacing: '0.1px',
  textTransform: 'uppercase',
  animation: 'fadeInDown 1s ease-out',
}));

export const CareerLogo = styled('img')(() => ({
  width: '100px',
  height: 'auto',
  animation: 'fadeIn 2s ease-out',
}));

export const CareerSeparator = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  width: '100%',
}));

export const CareerWord = styled('span')(() => ({
  fontWeight: 300,
  color: '#555',
}));

export const CareerParentLine = styled('hr')(() => ({
  flexGrow: 1,
  border: 'none',
  borderTop: '2px solid #aaa',
  margin: 0,
}));

export const CareerGrid = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '550px',
  paddingBottom: '1rem',
}));

export const CareerNoVacanciesContainer = styled('div')(() => ({
  textAlign: 'center',
  marginTop: '2rem',
}));

export const CareerNoVacanciesMessage = styled('div')(() => ({
  fontSize: '1.5rem',
  margin: '1rem 0',
}));

export const CareerContainer = styled('div')(() => ({
  width: '340px',
  height: '200px',
  padding: 14,
  background: 'white',
  boxShadow: '0px 10px 20px rgba(18, 38, 63, 0.03)',
  borderRadius: 6,
  borderTop: '2px #076698 solid',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: 8,
}));

export const CareerStretch = styled('div')(() => ({
  alignSelf: 'stretch',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  display: 'flex',
  gap: 8,
}));

export const CareerColumn = styled('div')(() => ({
  flex: '1 1 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 4,
}));

export const CareerText = styled('div')(({ color, fontSize, fontWeight }) => ({
  color: color || '#5A5555',
  fontSize: fontSize || 10,
  fontFamily: 'Poppins',
  fontWeight: fontWeight || 400,
  wordWrap: 'break-word',
}));

export const CareerLocation = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 8,
}));

export const CareerDot = styled('div')(() => ({
  width: 4,
  height: 4,
  background: '#C9D6E1',
  borderRadius: '50%',
}));

export const CareerFooter = styled('div')(() => ({
  width: 303,
  padding: '0 10px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 18,
  marginTop: 'auto',
}));

export const CareerLine = styled('div')(() => ({
  width: 298,
  height: 0,
  border: '1px #C8C9CA solid',
}));

export const CareerLinkContainer = styled('div')(() => ({
  alignSelf: 'stretch',
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CareerLink = styled('div')(() => ({
  color: '#137FC3',
  fontSize: 14,
  fontFamily: 'Poppins',
  fontWeight: 600,
  textDecoration: 'underline',
  textTransform: 'capitalize',
}));