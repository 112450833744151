import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import CalendarPicker from '../components/calendar/CalendarPicker'
import JobDetails from '../components/card/JobDetails'
import CompanyDetails from '../components/card/CompanyDetails'
import apiCalendar from '../services/api/calendar'
import {
  CalendarCard,
  CalendarLogoContainer,
  CardHeader,
  ImageContainer,
  LogoImage,
  MainContainer,
  Separator,
  StyledCol,
  StyledColFlex,
  StyledContainer,
  StyledRow,
  StyledRowAuto,
} from '../components/styled'
import FullLogo from '../assets/logos/full-logo.png'
import defaultLogo from '../assets/mini-logo.png'
import LoadingPage from '../components/loadingPage'
import { formatCountryName } from '../utilities/stringUtilities'
import NotificationBoxPage from '../components/notificationBoxPage'

function Calendar() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { token } = useParams()
  const msgRef = useRef(null)
  const [data, setData] = useState()
  const [isLoaded, setIsLoaded] = useState(false)
  const [invalidLink, setInvalidLink] = useState(false)

  useEffect(() => {
    apiCalendar
      .getSingle(token)
      .then((res) => {
        if (res.type.toLowerCase() === 'form') navigate(`/interview/${token}`)
        setData(res)
        if (res.isBooked) {
          const declineStatus = { color: '#000000', name: 'Declined' }
          const isDeclined = res?.status?.find(
            (item) =>
              item.name === declineStatus.name &&
              item.color === declineStatus.color,
          )
          if (isDeclined)
            msgRef.current = t('pages.youDeclined', {
              position: res.position.title,
            })
          else msgRef.current = t('pages.youBookedTime')
          return
        }
        msgRef.current = t('pages.bookDate')
        setIsLoaded(true)
      })
      .catch((error) => {
        setInvalidLink(true)
        msgRef.current =
          error?.response?.data?.description || t('pages.interviewExpired')
      })
  }, [])

  const companyDetailsData = useMemo(
    () => ({
      location: data?.company?.workingLocations.map((location, index) => (
        <span className="me-1" key={location}>
          {formatCountryName(location)}
          {index < (data?.company?.workingLocations.length ?? 0) - 1 && ','}
        </span>
      )),
      size: data?.company?.companySize,
      website: data?.company?.website,
      industry: data?.company?.industry,
      logo: data?.company.logo,
    }),
    [data],
  )

  const jobDetailsData = useMemo(
    () => ({
      desc: data?.position?.description,
      startDate: data?.position?.startDate,
      endDate: data?.position?.endDate,
    }),
    [data],
  )

  if (!isLoaded && !invalidLink) {
    return <LoadingPage />
  }

  if (invalidLink) {
    return (
      <NotificationBoxPage
        messageType="error"
        message={t('pages.linkExpired')}
        isExpired
      />
    )
  }

  return (
    <MainContainer centered>
      <ImageContainer>
        <img height="auto" width="200px" src={FullLogo} alt="" />
      </ImageContainer>
      <StyledContainer fluid="md">
        <StyledRow className="calendar-page">
          <StyledCol md={12} lg={6}>
            <CalendarPicker
              title={msgRef.current}
              token={token}
              endDate={data?.position?.endDate}
            />
          </StyledCol>
          <StyledColFlex>
            <Row style={{ position: 'relative' }}>
              <CalendarLogoContainer>
                <LogoImage
                  src={data?.company.logo || defaultLogo}
                  alt="logo"
                  width="80px"
                />
              </CalendarLogoContainer>
              <CalendarCard height="100%">
                <CardHeader>{data?.company?.companyName}</CardHeader>
                <Separator />
                <CompanyDetails {...companyDetailsData} />
              </CalendarCard>
            </Row>
            <StyledRowAuto>
              <CalendarCard height="100%">
                <CardHeader>{data?.position?.title}</CardHeader>
                <Separator />
                <JobDetails {...jobDetailsData} />
              </CalendarCard>
            </StyledRowAuto>
          </StyledColFlex>
        </StyledRow>
      </StyledContainer>
    </MainContainer>
  )
}
export default Calendar
