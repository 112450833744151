// mapped to enums in api/models/enum_fields.py
export const getFormattedDate = (date) => {
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${year}-${month}-${day}`
}

export const currency = [
  { value: 'USD', label: 'USD' },
  { value: 'CAD', label: 'CAD' },
  { value: 'GBP', label: 'GBP' },
  { value: 'EUR', label: 'EUR' },
  { value: 'SAR', label: 'SAR' },
  { value: 'QAR', label: 'QAR' },
  { value: 'AED', label: 'AED' },
  { value: 'EGP', label: 'EGP' },
]

export const employmentType = [
  { value: 'FULL_TIME', en: 'Full-Time', ar: 'دوام كامل', gr: 'Vollzeit' },
  { value: 'PART_TIME', en: 'Part-Time', ar: 'دوام جزئي', gr: 'Teilzeit' },
  { value: 'FREELANCE', en: 'Freelance', ar: 'عامل حر', gr: 'Freiberuflich' },
  {
    value: 'PROJECT_BASED',
    en: 'Project-Based',
    ar: 'قائم على المشروع',
    gr: 'Projektbasiert',
  },
  { value: 'INTERNSHIP', en: 'Internship', ar: 'تدريب', gr: 'Praktikum' },
]

export const seniorityLevel = [
  { value: 'JUNIOR', en: 'Junior', ar: 'مبتدئ', gr: 'Junior' },
  { value: 'EXPERIENCED', en: 'Experienced', ar: 'ذوي الخبرة', gr: 'Erfahren' },
  {
    value: 'MID_SENIOR',
    en: 'Mid-Senior',
    ar: 'مستوى متوسط',
    gr: 'Mittleres Senior',
  },
  { value: 'SENIOR', en: 'Senior', ar: 'منصب رفيع', gr: 'Senior' },
  { value: 'MANAGERIAL', en: 'Managerial', ar: 'إداري', gr: 'Managerial' },
]

export const educationLevel = [
  {
    value: 'UNDERGRAD',
    en: 'Undergraduate',
    ar: 'المرحلة الجامعية',
    gr: 'Hochschüler',
  },
  { value: 'BACHELOR', en: 'Bachelor', ar: 'بكالوريوس', gr: 'Bachelor' },
  { value: 'MASTER', en: "Master's Degree", ar: 'الماجستير', gr: 'Master' },
  { value: 'DOCTORATE', en: 'Doctorate Degree', ar: 'دكتوراه', gr: 'Doktorat' },
]

export const travelFreq = [
  { value: 'REGULAR', en: 'Regular', ar: 'منتظم', gr: 'Regulär' },
  { value: 'SOMETIMES', en: 'Sometimes', ar: 'أحيانا', gr: 'Manchmal' },
  { value: 'RARELY', en: 'Rarely', ar: 'نادرًا', gr: 'Selten' },
  { value: 'NEVER', en: 'Never', ar: 'أبداً', gr: 'Niemals' },
]

export const languages = [
  { value: 'AF', en: 'Afrikaans', ar: 'أفريقية', gr: 'Afrikaans' },
  { value: 'SQ', en: 'Albanian', ar: 'ألبانية', gr: 'Albanisch' },
  { value: 'AR', en: 'Arabic', ar: 'عربى', gr: 'Arabisch' },
  { value: 'HY', en: 'Armenian', ar: 'أرمينية', gr: 'Armenisch' },
  { value: 'EU', en: 'Basque', ar: 'باسكي', gr: 'Baskisch' },
  { value: 'BN', en: 'Bengali', ar: 'البنغالية', gr: 'Bengalisch' },
  { value: 'BG', en: 'Bulgarian', ar: 'البلغارية', gr: 'Bulgarisch' },
  { value: 'CA', en: 'Catalan', ar: 'الكتالانية', gr: 'Katalanisch' },
  { value: 'KM', en: 'Cambodian', ar: 'كمبودية', gr: 'Kambodschanisch' },
  {
    value: 'ZH',
    en: 'Chinese (Mandarin)',
    ar: 'صيني',
    gr: 'Chinesisch (Mandarin)',
  },
  { value: 'HR', en: 'Croatian', ar: 'الكرواتية', gr: 'Kroatisch' },
  { value: 'CS', en: 'Czech', ar: 'التشيكية', gr: 'Tschechisch' },
  { value: 'DA', en: 'Danish', ar: 'الدنماركية', gr: 'Dänisch' },
  { value: 'NL', en: 'Dutch', ar: 'هولندي', gr: 'Niederländisch' },
  { value: 'EN', en: 'English', ar: 'إنجليزية', gr: 'Englisch' },
  { value: 'ET', en: 'Estonian', ar: 'الإستونية', gr: 'Estnisch' },
  { value: 'FJ', en: 'Fiji', ar: 'فيجي', gr: 'Fidschi' },
  { value: 'FI', en: 'Finnish', ar: 'الفنلندية', gr: 'Finnisch' },
  { value: 'FR', en: 'French', ar: 'الفرنسية', gr: 'Französisch' },
  { value: 'KA', en: 'Georgian', ar: 'الجورجية', gr: 'Georgisch' },
  { value: 'DE', en: 'German', ar: 'ألمانية', gr: 'Deutsch' },
  { value: 'EL', en: 'Greek', ar: 'اليونانية', gr: 'Griechisch' },
  { value: 'GU', en: 'Gujarati', ar: 'الغوجاراتية', gr: 'Gujarati' },
  { value: 'HE', en: 'Hebrew', ar: 'العبرية', gr: 'Hebräisch' },
  { value: 'HI', en: 'Hindi', ar: 'الهندية', gr: 'Hindi' },
  { value: 'HU', en: 'Hungarian', ar: 'المجرية', gr: 'Ungarisch' },
  { value: 'IS', en: 'Icelandic', ar: 'الأيسلندية', gr: 'Isländisch' },
  { value: 'ID', en: 'Indonesian', ar: 'الأندونيسية', gr: 'Indonesisch' },
  { value: 'GA', en: 'Irish', ar: 'الأيرلندية', gr: 'Irisch' },
  { value: 'IT', en: 'Italian', ar: 'الإيطالية', gr: 'Italienisch' },
  { value: 'JA', en: 'Japanese', ar: 'اليابانية', gr: 'Japanisch' },
  { value: 'JW', en: 'Javanese', ar: 'الجاوية', gr: 'Javanisch' },
  { value: 'KO', en: 'Korean', ar: 'الكورية', gr: 'Koreanisch' },
  { value: 'LA', en: 'Latin', ar: 'لاتينية', gr: 'Latein' },
  { value: 'LV', en: 'Latvian', ar: 'اللاتفية', gr: 'Lettisch' },
  { value: 'LT', en: 'Lithuanian', ar: 'الليتوانية', gr: 'Litauisch' },
  { value: 'MK', en: 'Macedonian', ar: 'المقدونية', gr: 'Mazedonisch' },
  { value: 'MS', en: 'Malay', ar: 'الماليزية', gr: 'Malaiisch' },
  { value: 'ML', en: 'Malayalam', ar: 'المالايالامية', gr: 'Malayalam' },
  { value: 'MT', en: 'Maltese', ar: 'المالطية', gr: 'Maltesisch' },
  { value: 'MI', en: 'Maori', ar: 'الماوري', gr: 'Maori' },
  { value: 'MR', en: 'Marathi', ar: 'الماراثية', gr: 'Marathi' },
  { value: 'MN', en: 'Mongolian', ar: 'المنغولية', gr: 'Mongolisch' },
  { value: 'NE', en: 'Nepali', ar: 'النيبالية', gr: 'Nepali' },
  { value: 'NO', en: 'Norwegian', ar: 'النرويجية', gr: 'Norwegisch' },
  { value: 'FA', en: 'Persian', ar: 'الفارسية', gr: 'Persisch' },
  { value: 'PL', en: 'Polish', ar: 'البولندية', gr: 'Polnisch' },
  { value: 'PT', en: 'Portuguese', ar: 'البرتغالية', gr: 'Portugiesisch' },
  { value: 'PA', en: 'Punjabi', ar: 'البنجابية', gr: 'Punjabi' },
  { value: 'QU', en: 'Quechua', ar: 'الكويتشوا', gr: 'Quechua' },
  { value: 'RO', en: 'Romanian', ar: 'الرومانية', gr: 'Rumänisch' },
  { value: 'RU', en: 'Russian', ar: 'الروسية', gr: 'Russisch' },
  { value: 'SM', en: 'Samoan', ar: 'السامواية', gr: 'Samoanisch' },
  { value: 'SR', en: 'Serbian', ar: 'الصربية', gr: 'Serbisch' },
  { value: 'SK', en: 'Slovak', ar: 'السلوفاكية', gr: 'Slowakisch' },
  { value: 'SL', en: 'Slovenian', ar: 'السلوفينية', gr: 'Slowenisch' },
  { value: 'ES', en: 'Spanish', ar: 'الإسبانية', gr: 'Spanisch' },
  { value: 'SW', en: 'Swahili', ar: 'السواحلية', gr: 'Swahili' },
  { value: 'SV', en: 'Swedish ', ar: 'السويدية', gr: 'Schwedisch' },
  { value: 'TA', en: 'Tamil', ar: 'التاميلية', gr: 'Tamilisch' },
  { value: 'TT', en: 'Tatar', ar: 'التتارية', gr: 'Tatarisch' },
  { value: 'TE', en: 'Telugu', ar: 'التيلجو', gr: 'Telugu' },
  { value: 'TH', en: 'Thai', ar: 'التايلاندية', gr: 'Thailändisch' },
  { value: 'BO', en: 'Tibetan', ar: 'التبتية', gr: 'Tibetisch' },
  { value: 'TO', en: 'Tonga', ar: 'تونغا', gr: 'Tonga' },
  { value: 'TR', en: 'Turkish', ar: 'التركية', gr: 'Türkisch' },
  { value: 'UK', en: 'Ukrainian', ar: 'الأوكرانية', gr: 'Ukrainisch' },
  { value: 'UR', en: 'Urdu', ar: 'الأردية', gr: 'Urdu' },
  { value: 'UZ', en: 'Uzbek', ar: 'الأوزبكية', gr: 'Usbekisch' },
  { value: 'VI', en: 'Vietnamese', ar: 'الفيتنامية', gr: 'Vietnamesisch' },
  { value: 'CY', en: 'Welsh', ar: 'الويلزية', gr: 'Walisisch' },
  { value: 'XH', en: 'Xhosa', ar: 'الخوسا', gr: 'Xhosa' },
]
